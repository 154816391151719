import React, { useMemo } from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { EXPERIMENTS } from '../../../constants';
import { AppWidgetProps, ModalType } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { openBillingPage } from '../../../utils/openBillingPage';
import { AlertModal } from './AlertModal';
import { WidgetContext } from './WidgetContext';

const LazyList = loadable(() => import('./List'));
const LazyStatus = loadable(() => import('./Status'));
const LazyCheckout = loadable(() => import('./Checkout'));
const LazyRestricted = loadable(() => import('./Restricted'));
const LazyUpgradeModal = loadable(() => import('./UpgradeModal'));

const Widget: React.FC<AppWidgetProps> = React.memo((props) => {
  return (
    <WidgetContext>
      <ViewSwitch {...props} />
      <Modal {...props} />
    </WidgetContext>
  );
});

const Modal: React.FC<AppWidgetProps> = ({
  modal,
  showUpgradeModal,
  closeUpgradeModal,
  subPage,
  continueToDemoCheckout,
  metaSiteId,
  biUpgradeReferralClick,
}) => {
  const { experiments } = useExperiments();
  const isAlertModalsEnabled = experiments.enabled(EXPERIMENTS.ALERT_MODALS);
  const onUpgrade = React.useCallback(() => {
    biUpgradeReferralClick('checkout-upgrade-modal');
    metaSiteId && openBillingPage(metaSiteId);
  }, [biUpgradeReferralClick, metaSiteId]);
  if (isAlertModalsEnabled) {
    if (modal.type === ModalType.None) {
      return null;
    }
    const { type, onClose } = modal;

    switch (type) {
      case ModalType.Upgrade:
        return (
          <LazyUpgradeModal
            onClose={onClose}
            onPreview={subPage.name === 'list' ? continueToDemoCheckout : onClose}
            onUpgrade={onUpgrade}
          />
        );
      default:
        return <AlertModal {...modal} />;
    }
  } else {
    return showUpgradeModal ? (
      <LazyUpgradeModal
        onClose={closeUpgradeModal}
        onPreview={subPage.name === 'list' ? continueToDemoCheckout : closeUpgradeModal}
        onUpgrade={onUpgrade}
      />
    ) : null;
  }
};

const ViewSwitch: React.FC<AppWidgetProps> = React.memo((props) => {
  const { subPage, navigateToHomePage } = props;
  const emptyState = useMemo(() => ({ isInWidget: false, onClick: () => navigateToHomePage() }), [navigateToHomePage]);

  switch (subPage.name) {
    case 'list':
    default:
      return <LazyList emptyState={emptyState} interactionEnd={PackagePickerInteractions.ListPageLoaded} {...props} />;
    case 'checkout':
      return (
        <LazyCheckout
          {...props}
          viewMode={props.host.viewMode}
          siteStyles={props.host.style}
          integrationData={subPage.checkoutData.integrationData}
        />
      );
    case 'status':
      return (
        <LazyStatus
          {...props}
          ownerDemo={subPage.statusData.ownerDemo}
          integrationData={subPage.statusData.purchaseData.checkoutData.integrationData}
        />
      );
    case 'restricted':
      return <LazyRestricted {...props} />;
  }
});

export default Widget;
